import { useState } from 'react'
import Slider from '../slider'
import './style.css'
import { Actions } from '../../../../common/src'

const OpportunityHindrance = ({ goals, goalResolutionPairs, setGoalResolutionPairs, setDone, goBack }) => {
  const setAndNext = (goal, resolution, isLast) => {
    setGoalResolutionPairs({ ...goalResolutionPairs, [goal]: resolution })
    if (!isLast) {
      setCurrentIndex(currentIndex + 1)
    } else {
      setDone()
    }
  }

  const formElements = goals.map((goal, i) => (
    <div>
      <div className="font-bold text-lg mb-2">Center your thoughts</div>
      <div className="text-sm text-gray-500 mb-2">For the best results, try not to skip this step</div>

      <div className={`max-w-md mx-auto my-4 p-4 border-slate-500 rounded-lg shadow-lg bg${(i % 4) + 1}`}>
        <h4 className="text-sm mb-1 text-center text-slate-800">To achieve</h4>
        <h2 className="text-xxl font-semibold mb-4 break-words text-center">{goal}</h2>
        <div className="relative">
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <div className="w-52 h-52 rounded-full opacity-20	bg-sky-950 border-zinc-600 border-2"></div>
          </div>
          <img src={`${process.env.PUBLIC_URL}/posture-${(i % 4) + 1}.png`} alt={goal} className="mx-auto mb-4" />
        </div>
        <h4 className="text-base mb-1 text-center text-slate-800 pt-2">I will</h4>
        <div className="flex justify-between flex-col">
          <div className="py-2 w-full min-w-64">
            <button
              onClick={() => setAndNext(goal, Actions.LET_GO, i === goals.length - 1)}
              className={`py-2 px-4 w-full rounded ${
                goalResolutionPairs[goal] === Actions.LET_GO
                  ? 'bg-slate-400 text-white'
                  : `btn${(i % 4) + 1} text-gray-800 hover:bg-slate-300`
              }`}
            >
              Let go
            </button>
          </div>
          <div className="py-2 w-full min-w-64">
            <button
              onClick={() => setAndNext(goal, Actions.BE_PRESENT, i === goals.length - 1)}
              className={`py-2 px-4 w-full rounded ${
                goalResolutionPairs[goal] === Actions.BE_PRESENT
                  ? 'bg-slate-400 text-white'
                  : `btn${(i % 4) + 1} text-gray-800 hover:bg-slate-300`
              }`}
            >
              Be present
            </button>
          </div>
          <div className="py-2 w-full min-w-64">
            <button
              onClick={() => setAndNext(goal, Actions.ACCEPT, i === goals.length - 1)}
              className={`py-2 px-4 w-full rounded ${
                goalResolutionPairs[goal] === Actions.ACCEPT
                  ? 'bg-slate-400 text-white'
                  : `btn${(i % 4) + 1} text-gray-800 hover:bg-slate-300`
              }`}
            >
              Accept
            </button>
          </div>
        </div>
        {goals.length > 1 && <div className="text-center text-xs text-gray-500 mt-4">{`${i + 1} of ${goals.length}`}</div>}
      </div>
    </div>
  ))

  const [currentIndex, setCurrentIndex] = useState(0)

  const handleNext = () => {
    if (currentIndex === formElements.length - 1) {
      setDone()
    } else {
      setCurrentIndex((currentIndex + 1) % formElements.length)
    }
    window.scrollTo(0, 0)
  }

  const handlePrevious = () => {
    if (currentIndex === 0) {
      goBack()
    } else {
      setCurrentIndex((currentIndex - 1 + formElements.length) % formElements.length)
    }
    window.scrollTo(0, 0)
  }

  return (
    <div className="w-fit">
      <Slider
        formElements={formElements}
        buttons={Array(goals.length).fill(['<', '>'])}
        footers={[]}
        currentIndex={currentIndex}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
      />
    </div>
  )
}

export default OpportunityHindrance
