// Slider.js
import React from 'react'
import './style.css' // Import the CSS file

const Slider = ({ formElements, buttons, currentIndex, handleNext, handlePrevious, footers }) => {
  return (
    <div className="slider-container">
      <div className="slides" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {formElements.map((formElement, index) => (
          <div
            key={index}
            className={`slide overflow-x-hidden max-w-full overflow-y-auto flex-col ${currentIndex === index ? 'active' : 'hidden'}`}
          >
            {buttons.flatMap((x) => x).some((x) => ['<', '>'].includes(x)) ? (
              <div className="flex justify-between">
                {
                  <div className="flex flex-col justify-center sm:pr-10">
                    <button
                      type="button"
                      onClick={handlePrevious}
                      className={`text-white bg-neutral-500 hover:bg-neutral-700 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2`}
                    >
                      <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 5H1m0 0l4-4m-4 4l4 4"
                        />
                      </svg>
                      <span className="sr-only">Right</span>
                    </button>
                  </div>
                }
                {formElement}
                {
                  <div className="flex flex-col justify-center sm:pl-10">
                    <button
                      type="button"
                      onClick={handleNext}
                      className={`text-white bg-neutral-500 hover:bg-neutral-700 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2`}
                    >
                      <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                      </svg>
                      <span className="sr-only">Right</span>
                    </button>
                  </div>
                }
              </div>
            ) : (
              formElement
            )}
            {buttons.flatMap((x) => x).some((x) => ['next', 'skip', 'prev'].includes(x)) && (
              <div className="w-full pt-3">
                <div className="flex justify-center">
                  {buttons[index].includes('prev') && (
                    <button type="button" className="hover:text-slate-500" onClick={handlePrevious} disabled={currentIndex === 0}>
                      Previous
                    </button>
                  )}
                  {buttons[index].includes('next') && buttons[index].includes('prev') && <div className="mx-2"></div>}
                  {buttons[index].includes('next') && (
                    <button
                      type="button"
                      className="hover:text-slate-500"
                      onClick={handleNext}
                      disabled={currentIndex === formElements.length - 1}
                    >
                      Next
                    </button>
                  )}
                  {((buttons[index].includes('skip') && buttons[index].includes('next')) ||
                    (buttons[index].includes('prev') && buttons[index].includes('skip'))) && <div className="mx-2"></div>}
                  {buttons[index].includes('skip') && (
                    <button
                      type="button"
                      className="hover:text-slate-500"
                      onClick={handleNext}
                      disabled={currentIndex === formElements.length - 1}
                    >
                      Skip
                    </button>
                  )}
                </div>
              </div>
            )}
            {footers[index] && <>{footers[index]}</>}
          </div>
        ))}
      </div>
    </div>
  )
}
export default Slider
