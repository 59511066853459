import React, { useState, useEffect } from 'react'
import Listen from '../listen'
import Waiting from '../waiting'
import { Actions } from '../../../../common/src'
import { tenMin } from './fallbacks/10min'
import { fiveMin } from './fallbacks/5min'
import { fifteenMin } from './fallbacks/15min'
import { twentyMin } from './fallbacks/20min'

// Main Component
const Guide = ({ goalResolutionPairs, ready }) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [timestampedAudio, setTimestampedAudio] = useState(null)

  useEffect(() => {
    if (!ready) return
    const call = fetch('https://api.guided-meditation-free.org', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ goalResolutionPairs }),
    })

    call
      .then((response) => response.json())
      .then((data) => {
        setTimestampedAudio(data.guideAudioWithTimestamp)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        // set to default

        const numberOfGoals = Object.keys(goalResolutionPairs).length
        if (numberOfGoals === 1) {
          setError(
            'Sorry, we encountered an error loading your tailored guided meditation. We would love for you to try again later, meanwhile we will play a general 5 min one instead'
          )
          setTimestampedAudio(fiveMin.guideAudioWithTimestamp)
        }
        if (numberOfGoals === 2) {
          setError(
            'Sorry, we encountered an error loading your tailored guided meditation. We would love for you to try again later, meanwhile we will play a general 10 min one instead'
          )
          setTimestampedAudio(tenMin.guideAudioWithTimestamp)
        }
        if (numberOfGoals >= 3) {
          setError(
            'Sorry, we encountered an error loading your tailored guided meditation. We would love for you to try again later, meanwhile we will play a general 15 min one instead'
          )
          setTimestampedAudio(fifteenMin.guideAudioWithTimestamp)
        }
        if (numberOfGoals > 3) {
          setError(
            'Sorry, we encountered an error loading your tailored guided meditation. We would love for you to try again later, meanwhile we will play a general 20 min one instead'
          )
          setTimestampedAudio(twentyMin.guideAudioWithTimestamp)
        }
        setLoading(false)
      })
  }, [ready])

  const handlePrint = () => {
    window.print()
  }

  return (
    <>
      {loading && <Waiting />}
      {!loading && timestampedAudio && <Listen timestampedAudio={timestampedAudio} error={error} />}
      <hr className="no-print border-t-2 border-gray-200 my-8 w-10/12" />
      <div className="no-print">
        <h3 className="pt-2 px-2 text-center text-base text-black">
          For the best results,{' '}
          <button onClick={handlePrint} className="hover:underline decoration-sky-700 text-sky-700">
            click here to print this note
          </button>{' '}
          and keep it with you.
        </h3>
      </div>
      <div className="flex flex-wrap flex-row w-full px-12 py-3">
        {Object.keys(goalResolutionPairs).map((key, index) => (
          <div className="md:w-1/3 sm:w-1/2 xs:w-full aspect-square">
            <div
              className="m-2 aspect-square bg-cover bg-center p-2 rounded-3xl flex flex-col justify-between sm:filter-none"
              key={key}
              style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/tile${index + 1}.png)`, backgroundSize: '120% 120%' }}
            >
              <h2 className="text-center text-black text-md font-bold">{key}</h2>
              <div className="flex-col-reverse">
                <p className="text-center capitalize text-base underline decoration-sky-500 font-medium decoration-4">
                  {goalResolutionPairs[key].replace(/_/g, ' ').toLowerCase()},
                </p>
                <p className="text-center text-sm font-medium">
                  {goalResolutionPairs[key] === Actions.LET_GO
                    ? 'not because it was never important, but because clinging to it prevents you from moving forward.'
                    : goalResolutionPairs[key] === Actions.BE_PRESENT
                    ? 'you see yourself clearly, enabling growth beyond who you were before.'
                    : 'and you align with reality, opening the door to inner calm and clarity.'}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Guide
