import React, { useState } from 'react'
import './style.css'
import { PhysicalComfort, MentalBalance, Motivation } from '../../../../common/src/index'

const MultiSelectDropdown = ({ selectedItems, setSelectedItems, totalTime, setTotalTime }) => {
  const physicalComfortKeys = Object.keys(PhysicalComfort)
  const mentalBalanceKeys = Object.keys(MentalBalance)
  const motivationKeys = Object.keys(Motivation)
  const allKeys = [...physicalComfortKeys, ...mentalBalanceKeys, ...motivationKeys]

  function optimizeStringPairs(strings) {
    // Sort strings by length to make pairing easier
    strings.sort((a, b) => a.length - b.length)

    let result = []
    while (strings.length > 1) {
      let str1 = strings.shift() // Take the shortest string
      let paired = false

      for (let i = 0; i < strings.length; i++) {
        let str2 = strings[i]
        let combinedLength = str1.length + str2.length

        if (combinedLength >= 15) {
          result.push(str1, str2)
          strings.splice(i, 1) // Remove the paired string from the list
          paired = true
          break
        }
      }

      if (!paired) {
        result.push(str1) // If no suitable pair is found, keep the string as is
      }
    }

    // Add any remaining string to the result (if the list had an odd number of elements)
    if (strings.length > 0) {
      result.push(strings[0])
    }

    return result
  }

  const keysCapitalized = optimizeStringPairs(allKeys).map((key) =>
    key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  )
  const [items, setItems] = useState(keysCapitalized)

  const [newItem, setNewItem] = useState('')

  const handleToggle = (item) => {
    const newSelectedItems = selectedItems.includes(item) ? selectedItems.filter((selected) => selected !== item) : [...selectedItems, item]
    setTotalTime(newSelectedItems.length * 5)
    setSelectedItems(newSelectedItems)
  }

  const handleAddItem = () => {
    if (newItem && !items.includes(newItem)) {
      setItems([...items, newItem])
      setSelectedItems([...selectedItems, newItem])
      setNewItem('')
    }
  }

  return (
    <div className="relative w-10/12">
      <div>
        <div className="font-bold text-lg mb-2">Set your intention</div>
        <div className="text-sm text-zinc-600 mb-2">For the best results, try not to pick too many</div>
        <div>
          {items.map((item, i) => (
            <button
              key={item}
              onClick={() => handleToggle(item)}
              className={`capitalize p-1 my-1 mx-2 w-fit ${
                selectedItems.includes(item) ? `bg-zen${(i % 9) + 1} border-zinc-600 outline` : 'bg-zen'
              } py-2 px-4 rounded mb-2 text-zinc-800 max-w-full overflow-hidden`}
            >
              {item}
            </button>
          ))}
        </div>
        <div className="my-10 mx-2 flex">
          <input
            className="rounded-lg w-full p-2"
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Add your own"
          />
          <button className="px-4" onClick={handleAddItem}>
            Add
          </button>
        </div>
      </div>
      <input type="hidden" readOnly name="selectedItems" value={selectedItems} />
    </div>
  )
}

export default MultiSelectDropdown
