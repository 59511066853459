import './App.css'
import Slider from './components/slider'
import MultiSelectDropdown from './components/multi-select'
import OpportunityHindrance from './components/oppo-hind'
import Guide from './components/guide'
import React, { useState, useEffect } from 'react'

function App() {
  const [selectedItems, setSelectedItems] = useState([])
  const [goalResolutionPairs, setGoalResolutionPairs] = useState([])
  const [totalTime, setTotalTime] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [ready, setReady] = useState(false)

  const [displayedTotalTime, setDisplayedTotalTime] = useState(totalTime)

  useEffect(() => {
    if (displayedTotalTime !== totalTime) {
      const interval = setInterval(() => {
        setDisplayedTotalTime((prevValue) => {
          const increment = Math.ceil((totalTime - prevValue) / 10)
          const newValue = prevValue + increment
          if (newValue >= totalTime) {
            clearInterval(interval)
            return totalTime
          }
          return newValue
        })
      }, 120) // Adjust speed of animation here!

      return () => clearInterval(interval)
    }
  }, [totalTime, displayedTotalTime])

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % formElements.length)
    window.scrollTo(0, 0)
  }

  const handlePrevious = () => {
    setCurrentIndex((currentIndex - 1 + formElements.length) % formElements.length)
    window.scrollTo(0, 0)
  }

  const handleOpportunityHindranceDone = () => {
    setCurrentIndex(currentIndex + 1)
    setReady(true)
  }

  const formElements = [
    <MultiSelectDropdown
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      totalTime={totalTime}
      setTotalTime={setTotalTime}
    />,
    <OpportunityHindrance
      goals={selectedItems}
      goalResolutionPairs={goalResolutionPairs}
      setGoalResolutionPairs={setGoalResolutionPairs}
      setDone={handleOpportunityHindranceDone}
      goBack={handlePrevious}
    />,
    <Guide goalResolutionPairs={goalResolutionPairs} ready={ready} />,
  ]

  const dropdownButtons = selectedItems.length > 0 ? ['next'] : []
  const oppoHindButtons = []

  const multiSelectFooter =
    selectedItems.length > 0 ? (
      <div className="text-zinc-600 text-sm">
        Your session will be <p className="font-bold inline">approx. {displayedTotalTime} mins</p>
        {displayedTotalTime > 15 && <p className="text-center">You can unselect to reduce it</p>}
      </div>
    ) : (
      <></>
    )

  const buttons = [dropdownButtons, oppoHindButtons, []]
  const footers = [multiSelectFooter]
  return (
    <div>
      <div className="bg-neutral-400 sm:background min-h-screen">
        <div className="md:mx-auto max-w-3xl font-montserrat">
          <div className="flex flex-col items-center justify-center min-h-fit pt-10 pb-20">
            <form onSubmit={handleSubmit} className="w-full min-h-fit pt-10">
              <Slider
                formElements={formElements}
                buttons={buttons}
                footers={footers}
                currentIndex={currentIndex}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
