import React, { useEffect, useRef, useState } from 'react'

const Listen = ({ timestampedAudio, error }) => {
  const mp4AudioRef = useRef(null)
  const mp3AudioRefs = useRef([])
  const [loopCount, setLoopCount] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [hasStarted, setHasStarted] = useState(false) // New state

  useEffect(() => {
    const mp4Audio = mp4AudioRef.current

    // Function to handle the end of the MP4 and loop
    const handleEnded = () => {
      setLoopCount((prevLoopCount) => prevLoopCount + 1)
      mp4Audio.play()
    }

    // Play the MP4 when loaded and set up event listeners
    if (mp4Audio) {
      mp4Audio.loop = false
      mp4Audio.addEventListener('ended', handleEnded)
      mp4Audio.addEventListener('play', (event) => {
        // fix issue
        if (!isPlaying) {
          setIsPlaying(true)
        }
      })
    }

    // Set up interval to check timestamps and play MP3s
    const intervalId = setInterval(() => {
      if (mp4Audio && mp4Audio.currentTime !== undefined) {
        const currentTime = mp4Audio.currentTime
        timestampedAudio.forEach((audio, index) => {
          const totalTimeElapsed = loopCount * mp4Audio.duration + currentTime
          if (
            totalTimeElapsed >= audio.timestamp &&
            totalTimeElapsed < audio.timestamp + 1 &&
            mp3AudioRefs.current[index] &&
            mp3AudioRefs.current[index].paused &&
            isPlaying
          ) {
            mp3AudioRefs.current[index].volume = 1
            mp3AudioRefs.current[index].play()
          }
          const lastAudio = timestampedAudio[timestampedAudio.length - 1]
          const lastAudioEndTime = lastAudio.timestamp + 15
          const totalDuration = loopCount * mp4Audio.duration + currentTime
          if (totalDuration >= lastAudioEndTime) {
            const fadeOutAndStop = () => {
              const mp4Audio = mp4AudioRef.current
              if (mp4Audio) {
                const fadeOutInterval = setInterval(() => {
                  if (mp4Audio.volume > 0.1) {
                    mp4Audio.volume -= 0.01
                  }
                  if (mp4Audio.volume <= 0.1) {
                    clearInterval(fadeOutInterval)
                    setIsPlaying(false)
                    mp4Audio.pause()
                  }
                }, 1000)
              }
            }

            fadeOutAndStop()
          }
        })
      }
    }, 500)

    // Clean up event listeners and interval on component unmount
    return () => {
      clearInterval(intervalId)
      if (mp4Audio) {
        mp4Audio.removeEventListener('ended', handleEnded)
      }
    }
  }, [timestampedAudio, isPlaying, loopCount])

  useEffect(() => {
    // Play/Pause control
    const mp4Audio = mp4AudioRef.current
    if (isPlaying) {
      mp4Audio.volume = 0.22
      mp4Audio.play()
    } else {
      mp4Audio.pause()
    }
  }, [isPlaying])

  const handleStart = () => {
    setHasStarted(true)
    setIsPlaying(true)
  }

  const togglePlayPause = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying)
  }

  const goBack15Seconds = () => {
    const mp4Audio = mp4AudioRef.current
    if (mp4Audio) {
      const newTime = mp4Audio.currentTime - 15
      if (newTime < 0) {
        if (loopCount > 0) {
          setLoopCount((prevLoopCount) => prevLoopCount - 1)
          mp4Audio.currentTime = mp4Audio.duration + newTime
        } else {
          mp4Audio.currentTime = 0
        }
      } else {
        mp4Audio.currentTime = newTime
      }
    }
  }

  const base64ToImageUrl = (base64String) => {
    return `data:audio/mpeg;base64,${base64String}`
  }

  return (
    <div className="no-print flex items-center" style={{ minHeight: '10vh' }}>
      <audio ref={mp4AudioRef} src={'./serenity.mp4'} />
      {timestampedAudio.map((audio, index) => (
        <audio key={index} ref={(el) => (mp3AudioRefs.current[index] = el)} src={base64ToImageUrl(audio.audio)} />
      ))}
      <div className="flex flex-col">
        <button className="font-bold" onClick={hasStarted ? togglePlayPause : handleStart} title="play" style={{ marginLeft: '10px' }}>
          {hasStarted && (isPlaying ? 'Pause' : 'Play')}
          {!hasStarted && (
            <div className="flex flex-row">
              <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z"
                  fill="#1C274C"
                />
              </svg>
              <div className="flex flex-col justify-center">
                <div className="px-2">Click here to start</div>
              </div>
            </div>
          )}
        </button>
        {hasStarted && error && (
          <div className="leading-3 pt-3 max-w-full mx-3">
            <p className="text-sm text-red-600 inline max-w-full">{`${error}, or you may choose to `}</p>
            <button
              className="text-sm inline"
              onClick={() => {
                window.location.reload()
              }}
            >
              refresh now
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Listen
