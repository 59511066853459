export enum PhysicalComfort {
  RELAXATION = "RELAXATION",
  PAIN_RELIEF = "PAIN_RELIEF",
  BETTER_SLEEP = "BETTER_SLEEP",
  MUSCLE_RELAXATION = "MUSCLE_RELAXATION",
}

export enum MentalBalance {
  STRESS_RELIEF = "STRESS_RELIEF",
  ANXIETY_REDUCTION = "ANXIETY_REDUCTION",
  CLARITY = "CLARITY",
  RESILIENCE = "RESILIENCE",
  INNER_PEACE = "INNER_PEACE",
  ANGER_MANAGEMENT = "ANGER_MANAGEMENT",
}

export enum Motivation {
  FOCUS = "FOCUS",
  CREATIVITY = "CREATIVITY",
  RESOLVE = "RESOLVE",
  PASSION = "PASSION",
}

export enum Actions {
  ACCEPT = "ACCEPT",
  BE_PRESENT = "BE_PRESENT",
  LET_GO = "LET_GO",
}

export type GoalResolutionPair = {
  [key in PhysicalComfort | MentalBalance | Motivation | string]: Actions;
};

const mentalBalanceQuotes: { [key in MentalBalance]: string } = {
  STRESS_RELIEF:
    "It's not the load that breaks you down, it's the way you carry it. — Lou Holtz",
  ANXIETY_REDUCTION:
    "Do not anticipate trouble, or worry about what may never happen. Keep in the sunlight. — Benjamin Franklin",
  CLARITY:
    "The clearer your mind, the closer you are to truth. — Marcus Aurelius",
  RESILIENCE:
    "The oak fought the wind and was broken, the willow bent when it must and survived. — Robert Jordan",
  INNER_PEACE: "Peace comes from within. Do not seek it without. — Buddha",
  ANGER_MANAGEMENT:
    "For every minute you remain angry, you give up sixty seconds of peace of mind. — Ralph Waldo Emerson",
};

const motivationQuotes: { [key in Motivation]: string } = {
  FOCUS:
    "Concentrate all your thoughts upon the work in hand. The sun's rays do not burn until brought to a focus. — Alexander Graham Bell",
  CREATIVITY: "Creativity is intelligence having fun. — Albert Einstein",
  RESOLVE:
    "The difference between the impossible and the possible lies in a person’s determination. — Tommy Lasorda",
  PASSION:
    "Passion is energy. Feel the power that comes from focusing on what excites you. — Oprah Winfrey",
};

const physicalComfortQuotes: { [key in PhysicalComfort]: string } = {
  RELAXATION:
    "Tension is who you think you should be. Relaxation is who you are. — Chinese Proverb",
  PAIN_RELIEF: "Pain is inevitable. Suffering is optional. — Haruki Murakami",
  BETTER_SLEEP: "Sleep is the best meditation. — Dalai Lama",
  MUSCLE_RELAXATION:
    "A calm mind brings inner strength and self-confidence, so that’s very important for good health. — Dalai Lama",
};

export const meditationGuideSentences: string[] = [
  "Be kind to your mind, allowing it to wander freely as you bring it back to the present moment.",
  "Embrace any discomfort, knowing that it's a part of your journey toward relaxation.",
  "There's no wrong way to experience this moment; trust yourself to find what works for you.",
  "Let go of any expectations and simply be present with whatever arises.",
  "As I guide you, feel free to adapt the notess in a way that suits your needs.",
  "Allow yourself to be generous with your breath, letting it flow naturally and deeply.",
  "Remember, this is your time; there's no rush to achieve anything—just be.",
  "As we move through this practice, listen to your body and honor what it needs.",
  "Take each notes as a suggestion, not a rule; your body knows what's best for you.",
  "Invite a sense of curiosity into your practice, exploring what feels right in each moment.",
];

const generateNotes = (goalResolutionPairs: GoalResolutionPair): string[] => {
  const notes: string[] = [];
  const goals = Object.keys(goalResolutionPairs);
  const randomizeGoals = () =>
    goals[Math.floor(Math.random() * goals.length)] ?? goals[0];

  const fallbackGoal = randomizeGoals();

  // Step 0: Introduction
  notes.push(`Instruct the participant to get ready.`);

  // Step 1: Physical Awareness (Opening)
  const firstPhysicalComfortGoal = Object.keys(goalResolutionPairs).filter(
    (g) => g in PhysicalComfort
  )[0];
  const firstPhysicalWithFallBack = firstPhysicalComfortGoal || fallbackGoal;
  notes.push(
    `Physical Awareness (Opening): Remind the participant that it is time to be in touch with their body, use the time generously for their touch and feel. Begin by focusing on your body, especially in relation to ${firstPhysicalWithFallBack}. As you become aware, ${goalResolutionPairs[firstPhysicalWithFallBack]} with each breath.`
  );

  // Step 2: Mental Awareness (Opening)
  const firstMentalGoal = Object.keys(goalResolutionPairs).filter(
    (g) => g in MentalBalance
  )[0];
  const firstMentalWithFallBack = firstMentalGoal || fallbackGoal;
  notes.push(
    `Mental Awareness (Opening): Shift your focus to your mind, particularly your feelings about ${firstMentalWithFallBack}. Allow yourself to ${goalResolutionPairs[firstMentalWithFallBack]} as thoughts arise.`
  );

  // Step 3-6: Alternating notess and quotes
  for (let i = 0; i < goals.length * 5 - 3; i++) {
    const goal = randomizeGoals();
    if (i === 1) {
      const quote =
        mentalBalanceQuotes[goal as MentalBalance] ||
        motivationQuotes[goal as Motivation] ||
        physicalComfortQuotes[goal as PhysicalComfort] ||
        `Gently share a wise quote to participant about their goal "${goal}".`;
      notes.push(`Gently share this quote with the participant: "${quote}"`);
    } else {
      notes.push(
        `Gently and without intrusion give an idea how they could achieve ${goal} by ${goalResolutionPairs[goal]}.`
      );
    }

    if (i % 2 === 0) {
      const nextGoal = randomizeGoals();
      notes.push(
        `Gently remind the participant they want to focus on ${nextGoal} and ${goalResolutionPairs[nextGoal]}, and invite them to imagine what that is like.`
      );
    }
  }

  // Step 7: Mental Awareness (Closing)
  notes.push(
    `Mental Awareness (Closing): Reflect on the changes in your thoughts regarding ${firstMentalGoal}. Notice any ease that came from your effort to ${goalResolutionPairs[firstMentalGoal]}.`
  );

  // Step 8: Physical Awareness (Closing)
  notes.push(
    `Physical Awareness (Closing): Conclude by re-focusing on your body. Compare how you feel now in relation to ${firstPhysicalComfortGoal}. ${goalResolutionPairs[firstPhysicalComfortGoal]} as you end this session.`
  );

  return notes;
};

export const generateNotesWithTimestamp = (
  goalResolutionPairs: GoalResolutionPair
): { note: string; type: "main" | "support" }[] => {
  // total time is number of goals * 5 minutes
  const notes = generateNotes(goalResolutionPairs);

  let notesWithTimestamp: { note: string; type: "main" | "support" }[] = [];
  notes.forEach((note, index) => {
    notesWithTimestamp.push({ note, type: "main" });
    if (
      (Object.keys(goalResolutionPairs).length == 1 && index === 2) ||
      (Object.keys(goalResolutionPairs).length > 1 && index % 3 === 1)
    ) {
      notesWithTimestamp.push({
        note: `Gently remind the participant "${
          meditationGuideSentences[
            Math.floor(Math.random() * meditationGuideSentences.length)
          ]
        }"`,
        type: "support",
      });
    }
  });
  return notesWithTimestamp;
};
